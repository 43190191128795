@font-face {
  font-family: 'Trade Gothic';
  src: url('./assets/fonts/TradeGothicLTStd-Extended.otf');
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/2CD257_3_0.eot');
  src: url('./assets/fonts/2CD257_3_0.woff');
  src: url('./assets/fonts/2CD257_3_0.woff2');
}
.more-info {
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 1000;
  -webkit-clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  background: rgba(0, 0, 0, 0.8);
  border: 0;
  width: 50px;
  height: 40px;
  color: white;
  font-family: serif !important;
  font-style: italic;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  cursor: pointer;
}
.more-info.strategy {
  animation: rotate infinite 2.5s;
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
  -webkit-animation: rotate infinite 2.5s;

}
.i {
  font-size: 30px;
  position: absolute;
  top: 18px;
  right: 32px;
  z-index: 1001;
  color: white;
  font-family: serif !important;
  font-style: italic;
  pointer-events: none;
}
.click-info {
  font-family: 'Trade Gothic';
  position: absolute;
  top: -200px;
  right: 0px;
  background: #e7b820;
  padding: 5px 26px 5px 20px;
  margin: 0;
  line-height: 1;
  animation: slide-in-from-right 1s;
  -webkit-animation: slide-in-from-right 1s;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes grow {
  from {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  to {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
  }
}
@keyframes grow {
  from {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  to {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
  }
}
@-webkit-keyframes slide-in-from-right {
  0% {
    top: -200px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    top: 0;
  }
}
@keyframes slide-in-from-right {
  0% {
    top: -200px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    top: 0;
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-alt {
  from {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate-alt {
  from {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.more-info:hover {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  animation: none;
  -webkit-animation: none;
}
@-webkit-keyframes shadow-pulse {
  from {
    -webkit-box-shadow: 2px 4px 6px red;
            box-shadow: 2px 4px 6px red;
  }
  to {
    -webkit-box-shadow: 2px 4px 6px red;
            box-shadow: 2px 4px 6px red;
  }
}
@keyframes shadow-pulse {
  from {
    -webkit-box-shadow: 2px 4px 6px red;
            box-shadow: 2px 4px 6px red;
  }
  to {
    -webkit-box-shadow: 2px 4px 6px red;
            box-shadow: 2px 4px 6px red;
  }
}

.slide-in {
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  color: white;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 70%;
  -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 20% 100%, 0 50%);
  clip-path: polygon(20% 0%, 100% 0, 100% 100%, 20% 100%, 0 50%);
  z-index: 999;
  animation: fade-in 420ms;
  -webkit-animation: fade-in 420ms;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.more-info + .slide-in {
  opacity: 1;
}
.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  animation: fade-in 2000ms;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation: fade-in 2000ms;
}
.iframe-container iframe,
.iframe-container object,
.iframe-container embed {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
p {
  font-size: 24px;
  font-family: 'Proxima Nova';
}
h1,
h2 {
  text-transform: uppercase;
  margin: 0 !important;
}
.bg {
  animation: pulse 6s infinite alternate ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.3;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-animation: pulse 6s infinite alternate ease-in-out;
}
.slide01 {
  text-align: left;
}
.slide01 h1 {
  margin: 0;
  font-weight: 900;
}
.slide01 h2 {
  font-weight: 100;
}
.main-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-title img {
  width: 180px;
  height: 180px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 40px;
}
.main-title div {
  width: calc(100% - 220px);
}
.main-title.top-left h1 {
  font-size: 17px;
}
.main-title.top-left h2 {
  font-size: 15px;
}
.spectacle-content {
  max-width: 1200px !important;
}
.next {
  width: 25%;
  padding: 20px 0;
  text-align: center;
  font-family: 'Trade Gothic';
  text-transform: uppercase;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.next:before {
  content: url('./assets/img/arrow-right.png');
  position: relative;
  right: 10px;
  top: -10px;
  width: 100%;
}
.next h5,
.previous h5 {
  margin: 0;
}
.previous {
  width: 25%;
  padding: 20px 0;
  text-align: center;
  font-family: 'Trade Gothic';
  text-transform: uppercase;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.previous:before {
  content: url('./assets/img/arrow-left.png');
  position: relative;
  left: 10px;
  top: -10px;
}
.main-title.top-left {
  position: absolute;
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 20px;
  top: 0;
  left: -50px;
  line-height: 1;
  width: 100%;
  z-index: 100;
}
.main-title.top-left img {
  width: 80px;
  height: 80px;
}
.slide-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slide-title {
  background: #e7b820;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: left;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: center;
  padding: 10px 150px 10px 85px;
  width: 50vw;
  animation: 800ms hex-enter;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation: 800ms hex-enter;
  -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
          clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
  text-align: left;
}
@-webkit-keyframes hex-enter {
  0% {
    opacity: 0;
    -webkit-clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, 0% 50%, 0% 0%);
  }

  75% {
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
  }
  100% {
    opacity: 1;
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
  }
}
@keyframes hex-enter {
  0% {
    opacity: 0;
    -webkit-clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, 0% 50%, 0% 0%);
  }

  75% {
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
  }
  100% {
    opacity: 1;
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
            clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
  }
}
.flywheel-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
          clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  padding: 120px 100px;
  background: rgb(103,204,226);
}
.flywheel-container video {
  width: 90%;
}
.slide-content a {
  text-decoration: none;
  color: #1f2022;
  border-bottom: 2px solid transparent;
  transition: 200ms ease-in-out;
  -webkit-transition: 200ms ease-in-out;
  -moz-transition: 200ms ease-in-out;
  -ms-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
}
.slide-content a:hover {
  text-decoration: none;
  border-bottom: 2px solid #1f2022;
}
.slide-content {
  text-align: left;
  position: relative;
  width: 50vw;
  right: 0;
  margin-left: auto;
  padding-left: 40px;
  padding-right: 100px;
}
.expando {
  position: absolute;
  top: 50px;
  right: 0;
  text-align: right;
  width: 54vw;
}
.expando.tooltip {
  right: 70px;
}
.expando-content {
  padding-right: 70px;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}
.expando h1 {
  font-size: 24px;
}
.expando-content p,
.expando-content li {
  text-align: left;
  font-size: 17px;
  line-height: 23px;
}
.expando-content ul {
  padding-left: 20px;
}
.expando-content li {
  margin: 0;
}
p,
ul li,
ol li {
  font-size: 17px;
  line-height: 23px;
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
  }
  100% {
    opacity: 0.4;
    transform: scale(1);
    -webkit-transform: ;
    -moz-transform: ;
    -ms-transform: ;
    -o-transform: ;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1.01);
    -webkit-transform: ;
    -moz-transform: ;
    -ms-transform: ;
    -o-transform: ;
  }
  100% {
    opacity: 0.4;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
ol li,
ul li {
  font-size: 18px;
  font-family: 'Proxima Nova';
  padding-bottom: 20px;
  text-align: left;
}

ol li strong {
  display: block;
  font-family: 'Trade Gothic';
  padding-bottom: 4px;
}

.slide-navigation {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 200px;
  min-width: 100px;
  height: 70px;
  opacity: 0.5;
}
.slide-next {
  right: 10px;
}
.slide-prev {
  left: 10px;
}
.slide-navigation h5 {
  font-size: 11px;
  text-transform: uppercase;
}

.slide-next:before {
  content: '';
  background-image: url('./assets/img/arrow-right.png');
  -webkit-filter: grayscale(1) brightness(50%);
          filter: grayscale(1) brightness(50%);
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.slide-prev:before {
  content: '';
  background-image: url('./assets/img/arrow-left.png');
  background-size: contain;
  -webkit-filter: grayscale(1) brightness(10%);
          filter: grayscale(1) brightness(10%);
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.grand-total-cost,
.grand-total {
  text-align: center;
}
.grand-total-cost {
  font-family: 'Trade Gothic';
  font-size: 60px;
  margin-top: 10px;
}
ul li ul {
  padding-left: 0;
  list-style-type: none;
  padding-top: 23px;
}
svg {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
img.slide-logo {
  margin-right: 10px;
}

table {
  font-size: 17px;
}

td {
  padding: 2px 30px;
}
.the-cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
.the-cost table,
.the-cost div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.the-cost-number {
  animation: fade-out-cost 1s;
  -webkit-animation: fade-out-cost 1s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform-origin: center;
          transform-origin: center;
  font-size: 50px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.the-cost-table {
  animation: fade-in-cost 1s;
  -webkit-animation: fade-in-cost 1s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.the-cost-table td {
  padding: 6px 26px 2px;
  margin: 0;
  line-height: 23px;
}
.the-cost-table tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.03);
}
.the-cost-table tr:last-child {
  background: rgba(0, 0, 0, 0.08);
}
.the-cost-table tr:first-child {
  text-transform: uppercase;
}
@-webkit-keyframes fade-out-cost {
  0% {
    opacity: 1;
  }
  50% {
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out-cost {
  0% {
    opacity: 1;
  }
  50% {
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-in-cost {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-cost {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  .slide-title h2 {
    font-size: 3vw;
  }
@media screen and (max-width: 1300px) {
  .expando-content ul {
    margin: 0;
  }
  .expando-content li,
 .expando-content p,
 p {
   font-size: 14px;
   margin: 0;
   padding-bottom: 20px;
 }
 .main-title.top-left h2 {
   font-size: 16px;
 }
 .main-title.top-left h2 {
   font-size: 15px;
 }
 .expando h1 {
   font-size: 18px;
 }
 .expando-content h1 {
   padding-bottom: 20px;
 }
 .spectacle-content {
   padding-top: 40px !important;
 }
 .i {
   top: 48px;
 }
}
@media (orientation: landscape) and (max-width: 1024px) {
  .salesforce .expando.tooltip {
    width: 40%;

  }
  .best-in-class-slide {
    width: 69vw;
  }
  .main-title h1 {
    font-size: 20px;
  }
  .main-title h2 {
    font-size: 17px;
  }
  .iframe-container {
    padding: 70px 70px 56.25%;
  }
  .the-cost {
    height: 90vh;
    width: 48vw;
    overflow-x: scroll;
    overflow-y: visible;
  }
  .i {
    top: 28px;
    right: 24px;
  }

  .the-cost-table {
    padding-top: 50px;
    font-size: 12px;
    margin-top: 50px;
  }
  .slide-content a {
    font-size: 18px;

  }
  .the-cost-table td {
    padding: 6px 6px 2px;
    margin: 0;
    line-height: 23px;
  }
  .flywheel-container {
    padding: 20px 0;
  }
  .slide-navigation {
    display: none;
  }
  .slide-title {
    padding: 10px 80px 10px 20px;
  }
  .main-title.top-left {
    top: 0;
    left: -70px;
  }
  .main-title.top-left img {
    width: 60px;
    height: 60px;
  }
  .main-title.top-left h2,
  .main-title.top-left h1 {
    font-size: 11px;
  }
  .click-info {
    font-size: 12px;
    display: none;
  }
  .iframe-container {
    margin: 100px;
  }
  .expando.tooltip {
    top: 34px;
    width: 100%;
  }
  .expando.tooltip h1 {
    font-size: 11px;
    top:16px;
  }
  .expando.tooltip p {
    display: none;
  }
  .more-info {
    top: 20px;
    right: 2px;
  }
  .spectacle-deck {
    height: 100%;
  }
  .expando {
    width: 100%;
    padding-left: 60px;
    height: 100%;
  }
  .expando-content h1 {
    text-align: left;
  }
  .slide-in {
    width: 100%;
    -webkit-clip-path: none;
            clip-path: none;
  }
  .expando-content {
    padding-bottom: 100px;
  }
}
.portrait-message {
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2000;
  text-align: center;
}
.portrait-message img {
  width: 100px;
}
@media (orientation: portrait) {
  .portrait-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .spectacle-deck {
    display: none;
  }
}
@media screen and (min-width:801px) {
  .iframe-container  {
    padding-top: 120px !important;
  }
}

@media (orientation: landscape) and (max-width: 812px) {
  .slide01 .main-title h1 {
    font-size: 4vw;
  }
  .slide01 .main-title h2 {
    font-size: 3.4vw;
  }

  .slide01 .main-title img {
    width: 100px;
  }
  .iframe-container {
    padding-top: 50px !important;
    z-index: 9000;
  }
  .expando.tooltip h1 {
    position: relative;
    top: 5px;
  }
  .more-info {
    top: 23px;
  }
  .expando-content h1 {
    font-size: 16px;
  }
  .expando-content li,
  .expando-content p {
    font-size: 14px;
  }
  .slide-in {
    z-index: 9001;
  }
  .slide-title {
    padding-left: 40px;
  }
  .more-info {
    z-index: 9002;
  }
  .i {
    top: 31px;
    z-index: 9003;
  }
  .expando-content {
    top: -50px;
    padding-top: 50px;
  }
  .takeaway-slide .slide-title,
  .maintenance-slide .slide-title,
  .best-in-class .slide-title {
    padding-top: 100px;
  }
  .drupal-costs {
    font-size: 17px;
  }
  .maintenance-slide table {
    font-size: 14px;
  }
  .maintenance-slide table td {
    padding: 0;
    font-size: 12px;
    padding: 2px 10px 2px 0;
  }
  .the-cost {
    width: 40vw;
  }
}
/* @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    .expando.tooltip p {
      display: initial;
    }
}
.home-page-title {
  font-size: 3vw;
} */